<template>
  <v-card :class="darkmode || 'grey lighten-5'" class="pa-4" flat>
    <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
      <v-btn @click="$router.go(-1)" depressed>
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("app.back") }}
      </v-btn>
    </div>
    <HeaderInfo :info="dataTable" showTotal etest title />
    <div :class="darkmode ? 'dark-mode' : 'white'" class="mb-5 pa-3">
      <v-expansion-panels inset>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span v-html="truncateHtml(dataTable.header.question)" />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-html="dataTable.header.question"
              class="question"
              @click="viewImage($event)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div :class="darkmode || 'white'">
      <div class="mx-3 d-flex py-5 align-center">
        <div class="d-inline-block">
          <span class="mr-5 font-weight-bold">
            {{ $t("e_test.table_header.statistics.students_correctly") }}
            : {{ dataTable.header.true }}
          </span>
          <span class="mr-5 font-weight-bold">
            {{ $t("e_test.table_header.statistics.students_are_wrong") }}
            : {{ dataTable.header.false }}
          </span>
          <span class="font-weight-bold">
            {{ $t("e_test.table_header.statistics.not_completing") }}
            : {{ dataTable.header.pass }}
          </span>
        </div>
        <v-spacer />
        <v-text-field
          v-model="table.search"
          :label="$t('app.search')"
          outlined
          dense
          hide-details
          clearable
          style="max-width: 250px"
          prepend-inner-icon="mdi-magnify"
          class="d-inline-block mr-2"
        />
        <v-select
          v-model="table.result"
          :items="answers"
          item-value="id"
          item-text="name"
          hide-details
          outlined
          dense
          class="body-2 mr-2 d-inline-block select-150"
          @change="chnageFilterAnswer"
        />
      </div>
      <v-data-table
        :headers="tableHeaders"
        :items="dataTable.body.data"
        :page.sync="table.page"
        :items-per-page="table.limit"
        :loading="table.loading"
        item-key="id"
        hide-default-footer
        class="elevation-0 mx-3"
      >
        <template #item.number="{item}">
          {{ dataTable.body.data.indexOf(item) + dataTable.body.from - 1 + 1 }}
        </template>
        <template #item.answer="{item}">
          <span v-if="item.result">
            {{ g_language === "eng" ? item.result.eng : item.result.ind }}
          </span>
        </template>
      </v-data-table>
      <Pagination
        :length="dataTable.body.last_page"
        :total="dataTable.body.total"
        :current-page="table.page"
        :limit="table.limit"
        :handler="paginateChange"
      />
      <ViewImage
        :viewFile="viewFile"
        :urlFile="urlFile"
        @close="viewFile = false"
      />
    </div>
  </v-card>
</template>

<script>
import store from "@/store";
import { detailStatistic } from "@/api/admin/academic/eTest";
import truncate from "truncate-html";
import { mapGetters } from "vuex";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    HeaderInfo: () => import("../../components/HeaderInfo"),
    Pagination: () => import("@/components/Pagination"),
    ViewImage: () => import("../../components/ViewImage")
  },
  created() {
    this.getDetailStatistic();
  },
  watch: {
    "table.search"() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.getDetailStatistic();
      }, doneTypingInterval);
    }
  },
  computed: {
    ...mapGetters(["g_language"]),
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  data() {
    return {
      urlFile: "",
      viewFile: false,
      answers: [
        { name: this.$i18n.t("app.all"), id: "" },
        { name: this.$i18n.t("e_test.filter_answer.correct"), id: 3 },
        { name: this.$i18n.t("e_test.filter_answer.incorrect"), id: 1 },
        { name: this.$i18n.t("e_test.filter_answer.not_doing"), id: 0 },
        { name: this.$i18n.t("e_test.filter_answer.less_precise"), id: 2 }
      ],
      tableHeaders: [
        {
          text: "#",
          value: "number",
          sortable: false
        },
        {
          text: this.$i18n.t("app.name"),
          value: "name",
          sortable: false
        },
        {
          text: this.$i18n.t("e_test.answer"),
          sortable: false,
          value: "answer"
        }
      ],
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false,
        result: ""
      },
      dataTable: {
        header: {},
        body: { data: [] }
      }
    };
  },
  methods: {
    viewImage(event) {
      if (event.target.src) {
        this.viewFile = true;
        this.urlFile = event.target.src;
      }
    },
    chnageFilterAnswer(id) {
      this.table.result = id;
      this.getDetailStatistic();
    },
    truncateHtml: html =>
      truncate(html, 10, { byWords: true, stripTags: true }),
    paginateChange(page, limit) {
      this.table.limit = limit;
      this.table.page = page;
      this.getDetailStatistic();
    },
    async getDetailStatistic() {
      this.table.loading = true;
      try {
        const response = await detailStatistic({
          etest_question_id: this.$route.params.questionId,
          ...this.table
        });
        if (response.data.code) {
          this.dataTable = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.table.loading = false;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.dark-mode {
  background: #121212;
  border: 10px solid #424242;
}
</style>
